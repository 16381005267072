import React from "react";
// import { Link } from "gatsby";
import Img from "gatsby-image";

import { Link } from 'gatsby-plugin-intl' // s4n

const Card = ({ article }) => {

  // @s4n: if article has no image uploaded we treat it as non blog post but an ordinary page
  if (null === article.node.image) {
    return null;
  }

  return (
    // <Link to={`/article/${article.node.slug}`} className="uk-link-reset">
    <Link to={`/${article.node.slug}`} className="uk-link-reset">
      <div className="uk-card uk-card-muted">
        <div className="uk-card-media-top">
          <Img
            fixed={article.node.image.childImageSharp.fixed}
            imgStyle={{ position: "static" }}
          />
        </div>
        <div className="uk-card-body">
          <p id="category" className="uk-text-uppercase">
            {article.node.category.name}
          </p>
          <p id="title" className="uk-text-large">
            {article.node.title}
          </p>

          {process.env.SOFT4NET_DISPLAY_AUTHOR === `true` &&
            <div>
              <hr className="uk-divider-small" />
              <div className="uk-grid-small uk-flex-left" data-uk-grid="true">
                <div>
                  {article.node.author.picture && (
                    <Img
                      fixed={article.node.author.picture.childImageSharp.fixed}
                      imgStyle={{ position: "static", borderRadius: "50%" }}
                    />
                  )}
                </div>
                <div className="uk-width-expand">
                  <p className="uk-margin-remove-bottom">
                    {article.node.author.name}
                  </p>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </Link>
  );
};

export default Card;
